import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Div, H3 } from '../Elements';
import { Divider } from '../Divider';
import { MarkdownBlock } from '../MarkdownBlock';
import { RelatedBlade } from '../../../contentful/blades';
import { ContentfulBladeKeyFeatures } from '../../../contentful/content-types/blades/blade-key-features';
// import { ContentfulBladeKeyFeatures } from '../../../contentful/content-types/blades/blade-key-features';
import sectionPadding from '../BladePadding';
import { Lazy } from '../Lazy';

export function isContentfulBladeKeyFeatures(
  blade: RelatedBlade
): blade is ContentfulBladeKeyFeatures {
  return blade.contentTypeId === 'bladeKeyFeatures';
}

interface KeyFeaturesBladeProps {
  blade: ContentfulBladeKeyFeatures;
}

const getClassnameFromBackgroundColor = new Map([
  ['white', 'bg-white'],
  ['light-blue', 'bg-blue5'],
]);

export const KeyFeaturesBlade: React.FC<KeyFeaturesBladeProps> = ({ blade }) => {
  return (
    <Div className={`body-1 ${getClassnameFromBackgroundColor.get(blade.backgroundColor) || ''}`}>
      <Container>
        {(blade.bladeBorder === 'border-top' || blade.bladeBorder === 'border') && (
          <Divider marginVertical={0} />
        )}
        <Div {...sectionPadding(blade.sectionPadding, blade.contentTypeId)}>
          <Row>
            <Col xs={12}>
              <H3 className={`pb3`}>{blade.title}</H3>
            </Col>
          </Row>
          <Row>
            {blade.keyFeature.map((feature: string, index: number) => {
              return (
                <React.Fragment key={`${index + blade.contentTypeId}-keyfeature`}>
                  <Col sm={5} xs={12} className={'pb3'}>
                    <div className="flex items-start">
                      <Lazy flexShrink={0}>
                        <img
                          className="mr2"
                          style={{ marginTop: '4px' }}
                          alt="cloudflare-checkmark"
                          src="/img/icons/orange/checkmark.svg"
                        />
                      </Lazy>
                      <MarkdownBlock pClassName={'mv0 di'}>{feature}</MarkdownBlock>
                    </div>
                  </Col>
                  <Col sm={1}></Col>
                </React.Fragment>
              );
            })}
          </Row>
        </Div>
        {(blade.bladeBorder === 'border-bottom' || blade.bladeBorder === 'border') && (
          <Divider marginVertical={0} />
        )}
      </Container>
    </Div>
  );
};

export default KeyFeaturesBlade;
