import React from 'react';
import { ContentfulRichTextColumnContentDataTarget } from '../../../contentful/content-types/blades/blade-richtext-columns';
import {
  EntryButton,
  EntryElementIcon,
} from '../../../contentful/content-types/blades/common/interfaces/common.interface';
import { ButtonCTA } from '../Button';
import { ContentfulAssetFile } from '../ContentfulAssetFile/ContentfulAssetFile';
import { AssetFile } from '../ContentfulAssetFile/interfaces/asset-file.interface';
import { Span } from '../Elements';
import { getContentfulIcon, getLocaleEntry } from './richtext.utils';
import './richTextRenderer.style.scss';

interface RichTextEntryRendererProps {
  targetNode: ContentfulRichTextColumnContentDataTarget;
  type: 'inline' | 'block';
}

export const RichTextEntryRenderer: React.FC<RichTextEntryRendererProps> = ({
  targetNode,
  type,
}) => {
  const { entry_button, entry_elementIcon, entry_assetFile } = targetNode;
  if (entry_button) {
    const button = getLocaleEntry<EntryButton>(entry_button);
    if (button) {
      return (
        <ButtonCTA
          href={button.url}
          buttonColor={button.buttonColor || 'blue'}
          buttonStyle={button.buttonWidth || 'narrow'}
          marginTop={[6, 6, 6, 7]}
          trackingAction={button.trackingAction}
          trackingCategory={button.trackingCategory}
          trackingLabel={button.trackingLabel}
        >
          {button.text}
        </ButtonCTA>
      );
    }
  }
  if (entry_elementIcon) {
    const icon = getLocaleEntry<EntryElementIcon>(entry_elementIcon);
    if (icon) {
      const ContentfulIcon = getContentfulIcon(icon.icon);
      return (
        <Span
          marginBottom={type === 'block' ? 2 : 0}
          display={type === 'block' ? 'flex' : 'inline-flex'}
        >
          <ContentfulIcon size={icon.size} color={icon.color} />
        </Span>
      );
    }
  }
  if (entry_assetFile) {
    const asset = getLocaleEntry<AssetFile>(entry_assetFile);
    if (asset) {
      return <ContentfulAssetFile assetFile={asset} />;
    }
  }
  return null;
};
