import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../layout/layout';
import { Blades } from '../common/refresh-blades/RefreshBlades';
import { GatsbyTemplateProps } from '../../common/interfaces/gatsby-template-props.interface';
import { AppContextProps } from '../../common/interfaces/locale-props.interface';
import { TopNav } from '../layout';
import { MainFooter } from '../layout/footer/footer.interfaces';
import { HrefLangs } from '../layout/header/HrefLangs';
import { Locale } from '../../common/util/locales';
import { MetaTags } from '../../contentful/content-types/elements/meta-tags.interface';
import { ContentfulPage } from '../../contentful/content-types/page/page.interface';
import { PageTemplates } from './templates/PageTemplates';
import { ZendeskChat } from '../../common/util/zendeskChat';
import { ContentfulNavigation } from '../../contentful/content-types/navigation';
import { joinPaths } from '../../common/util/path';

export const query = graphql`
  query($locale: String!, $contentfulId: String!) {
    page(contentfulId: { eq: $contentfulId }, locale: { eq: $locale }) {
      ...pageFragment
    }
    navData: mainTopNav(contentfulId: { eq: "3Ygy1wQCWTJl9iFRLTd0NV" }, locale: { eq: $locale }) {
      ...mainTopNavFragment
    }
    redwoodNavData: navNavigation(
      contentfulId: { eq: "5BEIZi0JdHhdU8ABizjFbY" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    redwoodRightNavData: navNavigation(
      contentfulId: { eq: "55yBvRCoANQ6LSDRS8y34S" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    utilityNavData: navNavigation(
      contentfulId: { eq: "3Ubk7uPrpCVkhQ3ZYXZLOp" }
      locale: { eq: $locale }
    ) {
      ...navigationFragment
    }
    footerData: mainFooter(locale: { eq: $locale }) {
      ...mainFooterFragment
    }
    navAbTestData: mainTopNav(
      contentfulId: { eq: "5g5R508U5ykm1WoKe5Q366" }
      locale: { eq: $locale }
    ) {
      ...mainTopNavAbTestFragment
    }
    site {
      siteMetadata {
        baseURL
      }
    }
  }
`;
export interface PageParams
  extends GatsbyTemplateProps<AppContextProps & { allowedHrefLangs?: Locale[] }> {
  data: {
    page: ContentfulPage;
    navData: TopNav;
    redwoodNavData: ContentfulNavigation;
    redwoodRightNavData: ContentfulNavigation;
    utilityNavData: ContentfulNavigation;
    footerData: MainFooter;
    site: any;
  };
}

const CmsPage: FC<PageParams> = ({ data, pageContext }) => {
  const baseURL = data.site?.siteMetadata?.baseURL || 'https://www.cloudflare.com';

  return (
    <Layout
      pageContext={pageContext}
      topNavData={data.navData}
      footerData={data.footerData}
      redwoodNavData={data.redwoodNavData}
      redwoodRightNavData={data.redwoodRightNavData}
      utilityNavData={data.utilityNavData}
      psaBanner={data.page.psaBanner}
      customHeaderLogoUrl={
        data.page.headerLogo?.file?.publicURL ? data.page.headerLogo.file.publicURL : undefined
      }
      hrefLangs={HrefLangs(
        pageContext.locale,
        pageContext.pathname,
        pageContext.baseURL,
        (pageContext.allowedHrefLangs as any) || undefined
      )}
    >
      <Helmet>
        {getMetaTagsJSX(baseURL, data.page.metaTags)}
        {data.page.noindex && <meta name="robots" content="noindex, nofollow"></meta>}
      </Helmet>

      {data.page.enableZendeskChat && <ZendeskChat />}
      {data.page.relatedBlades && (
        <Blades page={data.page} pagePrefix="/refresh" refreshStyles={true}></Blades>
      )}
      {data.page.template && (
        <PageTemplates page={data.page} pagePrefix="/refresh" refreshStyles={true} />
      )}
    </Layout>
  );
};

/**
 * Returns a list of elements for react-helmet. Since react-helmet can't
 * support sub-components or fragments, we'll just return an array of jsx
 * @param metaTags An object that has a reference to metaTags
 */
export function getMetaTagsJSX(baseURL: string, metaTags?: MetaTags) {
  if (!metaTags) return [];

  return [
    metaTags.metaTitle && <title>{`${metaTags.metaTitle} | Cloudflare`}</title>,
    metaTags.metaTitle && <meta name="title" content={`${metaTags.metaTitle} | Cloudflare`} />,
    metaTags.metaDescription && <meta name="description" content={metaTags.metaDescription} />,
    metaTags.metaImage && metaTags.metaImage.file && (
      <meta name="image" content={metaTags.metaImage.file.publicURL} />
    ),
    <meta
      name="twitter:card"
      content={
        metaTags.twitterCustomImage?.file || metaTags.metaImage?.file
          ? 'summary_large_image'
          : 'summary'
      }
    />,
    metaTags.metaTitle && (
      <meta name="twitter:title" content={`${metaTags.metaTitle} | Cloudflare`} />
    ),
    (metaTags.twitterCustomImage || metaTags.metaImage) && (
      <meta
        name="twitter:image"
        content={
          metaTags.twitterCustomImage?.file
            ? joinPaths(baseURL, metaTags.twitterCustomImage.file.publicURL)
            : metaTags.metaImage?.file
            ? joinPaths(baseURL, metaTags.metaImage.file?.publicURL)
            : '../../../static/img/cf-twitter-card.png'
        }
      />
    ),
    metaTags.metaDescription && (
      <meta name="twitter:description" content={metaTags.metaDescription} />
    ),
    metaTags.metaTitle && <meta property="og:title" content={metaTags.metaTitle} />,
    metaTags.metaDescription && (
      <meta property="og:description" content={metaTags.metaDescription} />
    ),
    (metaTags.facebookCustomImage || metaTags.metaImage) && (
      <meta
        property="og:image"
        content={
          metaTags.facebookCustomImage?.file
            ? joinPaths(baseURL, metaTags.facebookCustomImage.file.publicURL)
            : metaTags.metaImage?.file
            ? joinPaths(baseURL, metaTags.metaImage.file.publicURL)
            : '../../../static/img/cf-facebook-card.png'
        }
      />
    ),
  ];
}

export default CmsPage;
