import * as React from 'react';
interface BladesProps {
  refreshStyles?: boolean;
  pagePrefix?: string;
  page: ContentfulPage;
}
import { ContentfulPage } from '../../../contentful/content-types/page/page.interface';

import { TemplateBladeList } from './TemplateBladeList';

interface BladesProps {
  refreshStyles?: boolean;
  pagePrefix?: string;
  page: ContentfulPage;
}

export const PageTemplates: React.FC<BladesProps> = props => (
  <>
    {props.page.template?.contentTypeId === 'templateBladeList' && (
      <TemplateBladeList page={props.page} pagePrefix="/refresh" refreshStyles={true} />
    )}
  </>
);
