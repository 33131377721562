import React from 'react';
import { Div, Span, Container, Row, Col, Img, Ul, Li } from '../Elements';
import { ContentfulBladeSignupForm } from '../../../contentful/content-types/blades/blade-signup-form';
import { RelatedBlade } from '../../../contentful/blades';
import { OnSuccessMessage } from '../Marketo/marketo-success-message';
import Text from '../Text/Text';
import { MarkdownBlock } from '../MarkdownBlock';
import { TextVariantMap } from '../Text/Text.interface';
import { Lazy } from '../Lazy';
import { Stream } from '@cloudflare/stream-react';
import { CFCheck } from '../CFIcons/CFCheck';
import { MarketoFormStyled } from '../Marketo/marketo-styled-form';

interface BladeSignUpFormProps {
  blade: ContentfulBladeSignupForm;
}

export function isSignUpFormBlade(blade: RelatedBlade): blade is ContentfulBladeSignupForm {
  return blade.contentTypeId === 'bladeSignUpForm';
}

export const BladeSignUpForm: React.FC<BladeSignUpFormProps> = ({ blade }) => {
  return (
    <Div
      className={blade.extraClass}
      backgroundColor="blue5"
      paddingTop={[4, 4, 4, 9]}
      paddingBottom={[7, 7, 7, 10]}
    >
      <Container>
        <Row display="flex" marginBottom={[6, 6, 6, 0]}>
          <Col marginBottom={[6, 6, 6, 0]} lg={5}>
            {blade.title && (
              <Text tag="Div" marginBottom={3} variant="headline2">
                {blade.title}
              </Text>
            )}
            {blade.subtitle && (
              <Div
                {...TextVariantMap.headline5}
                marginBottom={2}
                dangerouslySetInnerHTML={{ __html: blade.subtitle || '' }}
              />
            )}
            {blade.copy && (
              <MarkdownBlock
                renderers={{
                  paragraph: ({ children }) => (
                    <Text marginBottom={2} variant="body1">
                      {children}
                    </Text>
                  ),
                  heading: ({ children }) => (
                    <Text marginBottom={2} variant="headline5">
                      {children}
                    </Text>
                  ),
                  list: ({ children }) => (
                    <Ul className="list" margin={0} padding={0} marginTop={3}>
                      {children}
                    </Ul>
                  ),
                  listItem: ({ children }) => (
                    <Li marginBottom={4} color="orange0">
                      <Span display="flex" alignItems="center" color="black">
                        <Span alignSelf="start" display="inline-flex">
                          <CFCheck size={32} className="inline-flex" color="orange0" />
                        </Span>
                        <Text variant="body1" tag="Span">
                          {children}
                        </Text>
                      </Span>
                    </Li>
                  ),
                }}
                source={blade.copy}
              />
            )}
            {blade.iconList?.map((icon, index) => (
              <Div marginTop={6} display="flex" flexDirection="column">
                <Div display="flex" alignItems="center">
                  <Lazy flexShrink={0} marginRight={2} height={6}>
                    <Img width={6} src={icon.file?.publicURL} />
                  </Lazy>
                  {blade.iconTitleList && blade.iconTitleList[index] && (
                    <MarkdownBlock
                      renderers={{
                        paragraph: ({ children }) => <Text variant="headline5">{children}</Text>,
                      }}
                      source={blade.iconTitleList[index]}
                    />
                  )}
                </Div>
                <Div marginLeft={7}>
                  {blade.iconCopyList && blade.iconCopyList[index] && (
                    <MarkdownBlock
                      renderers={{
                        paragraph: ({ children }) => <Text variant="body2">{children}</Text>,
                      }}
                      source={blade.iconCopyList[index]}
                    />
                  )}
                </Div>
              </Div>
            ))}
            {blade.streamVideoId && (
              <Lazy>
                <Stream
                  src={blade.streamVideoId}
                  poster={blade.streamThumbnails}
                  controls={blade.streamOptions?.includes('controls')}
                  autoplay={blade.streamOptions?.includes('autoplay')}
                  muted={blade.streamOptions?.includes('muted')}
                  loop={blade.streamOptions?.includes('loop')}
                  preload="auto"
                />
              </Lazy>
            )}
          </Col>
          <Col lg={{ span: 4, offset: 2 }}>
            {blade.rightSideCopyPosition === false && (
              <MarkdownBlock
                renderers={{
                  paragraph: ({ children }) => <Text variant="body2">{children}</Text>,
                }}
                source={blade.rightSideCopy}
              />
            )}
            {blade.marketoEmbeddedForm && (
              <Div>
                <MarketoFormStyled
                  title={blade.formTitle}
                  marketoData={blade.marketoEmbeddedForm}
                  onSuccess={() => null}
                  SuccessElement={() =>
                    blade.marketoEmbeddedForm ? (
                      <OnSuccessMessage form={blade.marketoEmbeddedForm} ctaAction={() => null} />
                    ) : null
                  }
                />
              </Div>
            )}
            {blade.rightSideCopyPosition === true && (
              <MarkdownBlock
                renderers={{
                  paragraph: ({ children }) => <Text variant="body2">{children}</Text>,
                }}
                source={blade.rightSideCopy}
              />
            )}
          </Col>
        </Row>
      </Container>
    </Div>
  );
};
