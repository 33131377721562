import * as React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { H2, P, Div, H3, A } from '../Elements';
import { Divider } from '../Divider';
import { ButtonCTA } from '../Button';
import { SelectDropdown } from '../SelectDropdown';
import { ContentfulAssetFile } from '../ContentfulAssetFile/ContentfulAssetFile';
import classNames from 'classnames';
import { Color } from '../Elements/types';
import { RelatedBlade } from '../../../contentful/blades';
import { ContentfulBladeFeatureList } from '../../../contentful/content-types/blades/blade-feature-list';
import { ContentfulFeatureList } from '../../../contentful/content-types/elements/feature-list.interface';
import { Subtitle } from '../Subtitle';

export function isContentfulBladeFeatureList(
  blade: RelatedBlade
): blade is ContentfulBladeFeatureList {
  return blade.contentTypeId === 'bladeFeatureList';
}

const getClassnameFromBackgroundColor = new Map<Color, string>([
  ['white', 'bg-white'],
  ['blue', 'bg-blue5'],
]);

function getButton(featureList: ContentfulFeatureList) {
  switch (featureList.buttonStyle) {
    case 'call-to-action':
      return (
        <ButtonCTA
          className={'mt7-ns mt6'}
          href={featureList.buttonUrl}
          buttonColor={'blue-outline'}
        >
          {featureList.buttonText}
        </ButtonCTA>
      );
    case 'learn-more':
      return (
        <A display="block" marginTop={4} className="learn-more" href={featureList.learnMoreUrl}>
          {featureList.learnMoreText}
        </A>
      );
  }
  return '';
}
interface BladeFeatureListProps {
  blade: ContentfulBladeFeatureList;
}

export const BladeFeatureList: React.FC<BladeFeatureListProps> = ({ blade }) => {
  const [currentSection, setSection] = React.useState(null as string | null);
  return (
    <div className={classNames(getClassnameFromBackgroundColor.get(blade.backgroundColor))}>
      <Container>
        {(blade.bladeBorder === 'border-top' || blade.bladeBorder === 'border') && (
          <Divider marginVertical={0} />
        )}
        <Div className="pb10-ns pt4 pb2 pt9-ns">
          {blade.subtitle && (
            <Row>
              <Col>
                <Subtitle marginBottom={[3, 1]}>{blade.subtitle}</Subtitle>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm={7}>
              <H2 className=" pb7-ns pb6">{blade.title}</H2>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {/* Desktop section selector */}
              {blade.featureLists && blade.featureLists.length > 1 && (
                <div className="w-100 dn db-l pb6">
                  {blade.featureLists.map((list, i, featureLists) => (
                    <a
                      key={`${list.contentfulId}-${i}`}
                      href="#"
                      className={classNames(
                        'fw7 learn-more-link1 pointer pb1',
                        { ml5: i > 0 && i < featureLists.length },
                        {
                          'bb bw2 blue0':
                            currentSection === list.contentfulId || (!currentSection && i === 0),
                        }
                      )}
                      onClick={e => {
                        e.preventDefault();
                        setSection(list.contentfulId);
                      }}
                    >
                      {list.title}
                    </a>
                  ))}
                </div>
              )}
              {/* Mobile section selector */}
              {blade.featureLists && blade.featureLists.length > 1 && (
                <div className="w-100 db dn-l mb4">
                  <SelectDropdown
                    onChange={e =>
                      e.target instanceof HTMLSelectElement && setSection(e.target.value)
                    }
                    backgroundColor={blade.backgroundColor}
                    value={currentSection || blade.featureLists[0]?.contentfulId}
                  >
                    {blade.featureLists &&
                      blade.featureLists.map(list => (
                        <option
                          key={`feature-list-dropdown-option-${list.contentfulId}`}
                          value={list.contentfulId}
                        >
                          {list.title}
                        </option>
                      ))}
                  </SelectDropdown>
                </div>
              )}
            </Col>
          </Row>
          {blade.featureLists &&
            blade.featureLists.map((list, i) => (
              <div
                key={`featureListMap${list.contentfulId}-${i}`}
                className={classNames(
                  'flex-row',
                  'flex-wrap',
                  { flex: list.contentfulId === currentSection || (!currentSection && i === 0) },
                  { dn: list.contentfulId !== currentSection }
                )}
                data-index={i}
              >
                <Row>
                  <Col sm={3} xs={12}>
                    <H3 className={'pb2'}>{list.title}</H3>
                    <P className=" ma0" fontWeight={4}>
                      {list.description}
                    </P>
                    {getButton(list)}
                  </Col>
                  <Col sm={1}></Col>
                  <Col sm={8} xs={12} className="flex flex-row flex-wrap mt6 mt0-l">
                    {list.features &&
                      list.features.map(f => (
                        <Col
                          xs={6}
                          sm={6}
                          lg={3}
                          key={`imgMap${f.title}-${i}`}
                          className="pl0-important pb7-ns pb6 lh-0"
                        >
                          <ContentfulAssetFile
                            assetFile={f.iconAssetFile}
                            fallBackUrl={f.icon?.file?.publicURL}
                            alt={`${f.title}-icon`}
                          />
                          <p className="pt2 fw6 ma0 f2 lh-title">{f.title}</p>
                        </Col>
                      ))}
                  </Col>
                </Row>
              </div>
            ))}
        </Div>
        {(blade.bladeBorder === 'border-bottom' || blade.bladeBorder === 'border') && (
          <Divider marginVertical={0} />
        )}
      </Container>
    </div>
  );
};
