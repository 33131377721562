import React from 'react';
import { Div } from '../Elements';
import { RelatedBlade } from '../../../contentful/blades';

import { ContentfulBladeHtml } from '../../../contentful/content-types/blades/html-blade';

interface HtmlBladeProps {
  blade: ContentfulBladeHtml;
}

export function isHtmlBlade(blade: RelatedBlade): blade is ContentfulBladeHtml {
  return blade.contentTypeId === 'bladeHtml';
}

const createMarkup = (htmlContent: string) => ({ __html: htmlContent });

export const BladeHtml: React.FC<HtmlBladeProps> = ({ blade }) => {
  return (
    <Div className="container" dangerouslySetInnerHTML={createMarkup(blade.htmlContent)}></Div>
  );
};
