import React from 'react';

let didLoadZendesk = false;

export const ZendeskChat: React.FC = props => {
  React.useEffect(() => {
    if (!didLoadZendesk) {
      let zdScript = document.createElement('script');
      zdScript.id = 'ze-snippet';
      zdScript.type = 'text/javascript';
      zdScript.src =
        'https://static.zdassets.com/ekr/snippet.js?key=1753ae53-eb3c-4522-a07c-68155375ed4c';
      document.head.appendChild(zdScript);
    }
  }, []);
  return null;
};
