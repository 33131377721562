import React from 'react';
import { User } from '../../contentful/content-types/personalization/personalization.interface';

export const useMerchandizeData = (): Partial<User> | null => {
  const [userData, setUserData] = React.useState<Partial<User> | null>();

  React.useEffect(() => {
    setUserData((window as any).redwood?.user);
  }, []);

  return userData || null;
};
