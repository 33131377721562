import * as React from 'react';
import {
  ResourceReference,
  ReferenceContentTypes,
  Resource,
} from '../../resource-hub/interfaces/resource.interface';
import { useState } from 'react';
import { StreamModal } from '../StreamModal';
import { RelatedBlade } from '../../../contentful/blades';
import { ContentfulBladeResourceGrid } from '../../../contentful/content-types/blades/blade-resource-grid';
import { Button, Col, Container, Div, P, Row } from '../Elements';
import { TextVariantMap } from '../Text/Text.interface';
import { ResourceCard } from '../../resource-hub/components/ResourceCard';
import Text from '../Text/Text';
import { useStaticStrings } from '../../../common/hooks/useStaticStrings';
import sectionPadding from '../BladePadding';
import { Divider } from '../Divider';

const getContentType = (resourceRef: ResourceReference) => {
  return resourceRef.contentTypeId as ReferenceContentTypes;
};

const parseResources = (resources: Resource[]): Resource[] => {
  return resources.map(r => {
    // Check linked resource and assign descriminate types for type inference
    if (r.resourceReference) {
      r.resourceReference.kind = getContentType(r.resourceReference);
      if (r.resourceReference.kind === 'mrkPage') {
        r.callToActionDestination = `/${r.resourceReference.url_pageUrl}/`;
      }
      if (r.resourceReference.kind === 'page') {
        r.callToActionDestination = `/${r.resourceReference.relativePath}/`;
      }
      if (r.resourceReference.kind === 'caseStudy') {
        r.callToActionDestination = `/case-studies/${r.resourceReference.nameUrlSlug}/`;
      }
    }
    return r;
  });
};

export function isContentfulBladeResourceGrid(
  blade: RelatedBlade
): blade is ContentfulBladeResourceGrid {
  return blade.contentTypeId === 'bladeResourceGrid';
}

interface BladeResourceGridProps {
  blade: ContentfulBladeResourceGrid;
}

export const BladeResourceGrid: React.FC<BladeResourceGridProps> = ({ blade }) => {
  const parsedResources = parseResources(blade.resourceList || []);
  const [currentPage, setPage] = useState(1);
  const paginatedResources = parsedResources.slice(0, 12 * currentPage);
  const staticStrings = useStaticStrings();
  // Setup Modal
  const [currentStreamId, setStreamId] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);

  return (
    <>
      <StreamModal
        isOpen={modalIsOpen}
        streamId={currentStreamId}
        closeModal={() => setIsOpen(false)}
      />
      {/* Resource Cards */}
      <Div backgroundColor={blade.backgroundColor || 'white'}>
        <Container
          {...sectionPadding(blade.sectionPadding, blade.contentTypeId)}
          color={blade.textColor || 'black'}
        >
          {(blade.bladeBorder === 'border-top' || blade.bladeBorder === 'border') && (
            <Divider paddingVertical={0} />
          )}
          <Row>
            <Col lg={12}>
              <Text marginBottom={[6, 6, 6, 7]} variant="headline2" tag="H2">
                {blade.title}
              </Text>
            </Col>
          </Row>
          <Row marginBottom={[2, 2, 2, 4]}>
            {paginatedResources.length ? (
              paginatedResources.map(r => {
                return (
                  <Col marginBottom={4} key={r.id} lg={4}>
                    <ResourceCard
                      resource={r}
                      ctaMethod={(streamId: string) => {
                        setStreamId(streamId);
                        setIsOpen(true);
                      }}
                    />
                  </Col>
                );
              })
            ) : (
              <Col lg={12}>
                <P fontWeight={7} fontSize={5} color="black">
                  {staticStrings.json_values.No_results__Try_removing_filters}
                </P>
              </Col>
            )}
          </Row>
          {/* Load More */}
          {!(parsedResources.length === paginatedResources.length) && (
            <Div display="flex" justifyContent="center">
              <Button
                type="button"
                aria-label="load more"
                backgroundColor="transparent"
                borderColor="blue1"
                border="all"
                color="blue1"
                className="wide-btn"
                focused={{
                  outlineWidth: 2,
                  outlineStyle: 'solid',
                  outlineInnerOffset: 2,
                  borderColor: 'transparent',
                }}
                borderWidth={2}
                paddingVertical={2}
                {...TextVariantMap.learnmore}
                onClick={() => setPage(currentPage + 1)}
              >
                {staticStrings.json_values.Load_more}
              </Button>
            </Div>
          )}
        </Container>
        {(blade.bladeBorder === 'border-top' || blade.bladeBorder === 'border') && (
          <Divider marginVertical={0} paddingVertical={0} className="container" />
        )}
      </Div>
    </>
  );
};
