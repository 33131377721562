import React from 'react';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import Text from '../Text/Text';
import { A, Hr, Img, Li, Ol, Span, Ul } from '../Elements';
import { LocaleLink } from '../links/LocaleLink';
import { RichTextEntryRenderer } from './EntryRenderer';
import { getLocaleEntry } from './richtext.utils';
import { Options } from '@contentful/rich-text-react-renderer';
import {
  EntryPage,
  RelatedAsset,
} from '../../../contentful/content-types/blades/common/interfaces/common.interface';
import { RichtextcustomOptions } from './richtext.interface';
import { AssetFile } from '../ContentfulAssetFile/interfaces/asset-file.interface';
import { Icon } from '../Icon';
import { Lazy } from '../Lazy';

export const richTextOptions = (options?: RichtextcustomOptions): Options => ({
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => (
      <Text
        tag="P"
        marginBottom={4}
        marginTop={0}
        variant="body1"
        {...options?.PARAGRAPH_styleprops}
      >
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_1]: (node: any, children: any) => (
      <Text tag="H1" marginBottom={3} variant="headline1">
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_2]: (node: any, children: any) => (
      <Text tag="H2" marginBottom={2} variant="headline2">
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_3]: (node: any, children: any) => (
      <Text tag="H3" marginBottom={2} variant="headline3">
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_4]: (node: any, children: any) => (
      <Text tag="H4" marginBottom={2} variant="headline4">
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_5]: (node: any, children: any) => (
      <Text tag="H5" marginBottom={2} variant="headline5">
        {children}
      </Text>
    ),
    [BLOCKS.HEADING_6]: (node: any, children: any) => (
      <Text tag="P" marginBottom={2} variant="small-caps">
        {children}
      </Text>
    ),
    [BLOCKS.QUOTE]: (node: any, children: any) => (
      <Text tag="Div" className="rt-quote" marginBottom={2} variant="quote1">
        {children}
      </Text>
    ),
    [BLOCKS.UL_LIST]: (node: any, children: any) => (
      <Ul className={options?.listIcon ? 'list' : ''} margin={0} padding={0} paddingLeft={2}>
        {children}
      </Ul>
    ),
    [BLOCKS.OL_LIST]: (node: any, children: any) => (
      <Ol margin={0} padding={0} paddingLeft={2}>
        {children}
      </Ol>
    ),
    [BLOCKS.LIST_ITEM]: (node: any, children: any) => (
      <Li color="orange0">
        <Span display="flex" alignItems="center" color="black">
          {options?.listIcon && (
            <Span alignSelf="start" display="inline-flex">
              {options?.listIcon}
            </Span>
          )}
          <Span>{children}</Span>
        </Span>
      </Li>
    ),
    [BLOCKS.HR]: (node: any, children: any) => <Hr border="top" borderColor="gray2" />,
    [INLINES.HYPERLINK]: (node: any, children: any) => (
      <A
        fontWeight={7}
        color="blue1"
        display="inline-flex"
        alignItems="center"
        href={node.data.uri}
      >
        {children}
      </A>
    ),
    [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
      const media = getLocaleEntry<RelatedAsset>(node?.data?.target?.related_asset);
      const file = media?.file;
      const contentType = file?.internal?.mediaType.split('/')[0];
      switch (contentType) {
        case 'image': {
          return (
            <Lazy>
              <Img width="100%" src={file?.publicURL} alt={media?.title} />
            </Lazy>
          );
        }
        default: {
          return (
            <A
              fontWeight={7}
              display="flex"
              color="blue1"
              alignItems="center"
              target="_blank"
              href={file?.publicURL || ''}
              marginBottom={7}
            >
              {children}{' '}
              <Span lineHeight={0} marginLeft={0}>
                <Icon type="link-caret" />
              </Span>
            </A>
          );
        }
      }
    },
    [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
      const file = getLocaleEntry<RelatedAsset>(node?.data?.target?.related_asset)?.file;
      return (
        <A
          fontWeight={7}
          display="inline-flex"
          color="blue1"
          alignItems="center"
          target="_blank"
          href={file?.publicURL || ''}
        >
          {children}
        </A>
      );
    },
    [INLINES.ENTRY_HYPERLINK]: (node: any, children: any) => {
      const page = getLocaleEntry<EntryPage>(node?.data?.target?.entry_page || []);
      const asset = getLocaleEntry<AssetFile>(node?.data?.target?.entry_assetFile || []);
      if (page) {
        return (
          <LocaleLink className="blue1" to={page?.relativePath || ''} variant="link">
            <Span
              fontWeight={7}
              display="inline-flex"
              alignItems="center"
              target="_blank"
              marginBottom={0}
            >
              {children}
            </Span>
          </LocaleLink>
        );
      }
      if (asset) {
        return (
          <A
            fontWeight={7}
            display="inline-flex"
            color="blue1"
            alignItems="center"
            target="_blank"
            href={
              asset?.brandfolderAsset?.file.publicURL ||
              asset.contentfulAsset?.file?.publicURL ||
              ''
            }
          >
            {children}
          </A>
        );
      }
    },
    [INLINES.EMBEDDED_ENTRY]: (node: any, children: any) => {
      return <RichTextEntryRenderer type="inline" targetNode={node?.data?.target} />;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: any, children: any) => {
      return <RichTextEntryRenderer type="block" targetNode={node?.data?.target} />;
    },
  },
  renderMark: {
    [MARKS.BOLD]: (text: any) => <Span fontWeight={6}>{text}</Span>,
    [MARKS.UNDERLINE]: (text: any) => (
      <u>
        <Span fontWeight="normal">{text}</Span>
      </u>
    ),
    [MARKS.ITALIC]: (text: any) => (
      <i>
        <Span fontWeight="normal">{text}</Span>
      </i>
    ),
  },
  renderText: (text: string) => text.replace(/\u00a0/g, ' '),
});
