import { makeStyledComponent } from './Elements';
import React from 'react';

type ButtonUnstyledProps = {
  className: string;
  href?: string;
  trackingCategory?: string;
  trackingAction?: string;
  trackingLabel?: string;
  buttonStyle?: 'narrow' | 'wide' | 'extra-wide';
  buttonColor?: 'orange' | 'orange-outline' | 'blue' | 'blue-outline';
};

const getClassnameFromButtonColor = new Map([
  ['orange', 'bg-orange1 white'],
  ['orange-outline', 'ba b--orange1 orange'],
  ['blue', 'bg-blue0 white'],
  ['blue-outline', 'ba b--blue0 blue0'],
]);

const ButtonUnstyled: React.FC<ButtonUnstyledProps> = props => {
  const buttonStyle = props.buttonStyle ? props.buttonStyle : 'narrow';
  const buttonClassNames = `button-cta f2 ls2 fw7 pv2 ph3 tc bw2 ${getClassnameFromButtonColor.get(
    props.buttonColor || ''
  )} ${buttonStyle + '-btn'} ${props.className}`;

  return (
    <a
      tabIndex={0}
      target={'_blank'}
      role="button"
      href={props.href ? props.href : '#'}
      className={buttonClassNames}
      data-tracking-category={props.trackingCategory}
      data-tracking-action={props.trackingAction}
      data-tracking-label={props.trackingLabel}
    >
      {props.children}
    </a>
  );
};

export const ButtonCTA = makeStyledComponent(ButtonUnstyled);
