import React from 'react';
import { Div, Container, Row, Col, Img, P, Span, Button, A, Ul, Li } from '../Elements';
import { RelatedBlade } from '../../../contentful/blades';
import { OnSuccessMessage } from '../Marketo/marketo-success-message';
import Text from '../Text/Text';
import { ContentfulBladeEnterpriseForm } from '../../../contentful/content-types/blades/blade-enterprise-form';
import { Lazy } from '../Lazy';
import { useStaticStrings } from '../../../common/hooks/useStaticStrings';
import { LocaleLink } from '../links/LocaleLink';
import { MaybeRedwood } from '../../../config';
import { i18nPhoneNumberMapping } from '../../../common/util/i18n-phone-mapping';
import { Icon } from '../Icon';
import { ContentfulFeature } from '../../../contentful/content-types/elements/feature';
import { CFInfo } from '../CFIcons/CFInfo';
import { CFCheck } from '../CFIcons/CFCheck';
import { CFXExit } from '../CFIcons/CFXExit';
import { InterpolatedText } from '../InterpolatedText';
import Modal from 'react-modal';
import Media from 'react-media';
import { MarketoFormStyled } from '../Marketo/marketo-styled-form';

const MoreFeaturesModal: React.FC<Pick<
  ContentfulBladeEnterpriseForm,
  | 'moreFeatures'
  | 'moreFeaturesModalTitle'
  | 'moreFeaturesLinkText'
  | 'moreFeaturesModalLearnMoreText'
  | 'moreFeaturesModalLearnMoreUrl'
>> = ({
  moreFeatures,
  moreFeaturesModalTitle,
  moreFeaturesLinkText,
  moreFeaturesModalLearnMoreText,
  moreFeaturesModalLearnMoreUrl,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const numberOfBottomRows = moreFeatures && moreFeatures?.length % 2 ? 1 : 2;
  return (
    <>
      <Button
        display="flex"
        lineHeight={0}
        padding={0}
        className="pointer"
        backgroundColor="transparent"
        border="none"
        onClick={() => setOpen(true)}
        alignItems="center"
        marginTop={4}
      >
        <Text tag="Span" variant="learnmore" color="blue1" marginRight={1}>
          {moreFeaturesLinkText}
        </Text>
        <Icon type="chevronRightblue" />
      </Button>
      <Modal
        closeTimeoutMS={200}
        isOpen={open}
        className="bg-blue4 h-auto min-h-100 outline-0"
        overlayClassName="fixed top-0 bottom-0 left-0 right-0 bg--grayOpaque z-max overflow-y-scroll"
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        shouldFocusAfterRender={false}
      >
        <Div paddingTop={[2, 2, 2, 6]} paddingBottom={[7, 7, 7, 6]} position="relative">
          <Container>
            <Div marginBottom={[2, 2, 2, 6]} textAlign="right">
              <Button
                onClick={() => setOpen(false)}
                display="inline-flex"
                style={{ right: '32px' }}
                lineHeight={0}
                className="pointer"
                padding={0}
                backgroundColor="transparent"
                border="none"
              >
                <CFXExit size={32} color="black" />
              </Button>
            </Div>
            <Row display="flex" justifyContent="center">
              <Col lg={6}>
                <Div marginBottom={3} display="flex">
                  {moreFeaturesModalTitle && (
                    <Text flex="auto" variant="headline2">
                      {moreFeaturesModalTitle}
                    </Text>
                  )}
                </Div>
                {moreFeatures && (
                  <Ul
                    flexWrap="wrap"
                    padding={0}
                    margin={0}
                    justifyContent="between"
                    display="flex"
                  >
                    {moreFeatures?.map((feature, index) => {
                      const isLastRowItem =
                        numberOfBottomRows === 2
                          ? index === moreFeatures.length - 1 || index === moreFeatures.length - 2
                          : index === moreFeatures.length - 1;
                      return (
                        <Li
                          padding={0}
                          marginBottom={isLastRowItem ? 0 : 3}
                          display="flex"
                          width={['100%', '100%', '100%', '50%']}
                          key={feature}
                        >
                          <Span lineHeight={0} marginRight={2}>
                            <CFCheck color="orange0" size={32} />
                          </Span>
                          <Text flex="auto" variant="body2">
                            {feature}
                          </Text>
                        </Li>
                      );
                    })}
                  </Ul>
                )}
                {moreFeaturesModalLearnMoreText && (
                  <Div marginTop={4}>
                    <LocaleLink to={moreFeaturesModalLearnMoreUrl || ''}>
                      <Text tag="Span" variant="learnmore" color="blue1" marginRight={1}>
                        {moreFeaturesModalLearnMoreText}
                      </Text>
                      <Icon type="chevronRightblue" />
                    </LocaleLink>
                  </Div>
                )}
              </Col>
            </Row>
          </Container>
        </Div>
      </Modal>
    </>
  );
};

interface FormFeaturesProps {
  feature: ContentfulFeature;
  lastIndex: boolean;
}

const FormFeatures: React.FC<FormFeaturesProps> = ({ feature, lastIndex }) => {
  const [showToolTip, setShowToolTip] = React.useState<boolean>(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const handleToolTip = () => {
    setShowToolTip(!showToolTip);
  };

  React.useEffect(() => {
    const onClickOutside = (e: MouseEvent) => {
      if (ref.current != null && !ref.current.contains(e.target as any)) {
        setShowToolTip(!showToolTip);
      }
    };
    if (showToolTip) {
      window.addEventListener('click', onClickOutside);
    }
    return () => {
      window.removeEventListener('click', onClickOutside);
    };
  }, [showToolTip]);

  return (
    <Div display="flex" marginBottom={lastIndex ? 0 : 3} justifyContent="start">
      <Span lineHeight={0} marginRight={2}>
        <CFCheck color="orange0" size={32} />
      </Span>
      <Div flex="auto" alignItems="center" display="flex">
        <Text flex={['auto', 'auto', 'auto', 'none']} variant="body1">
          <InterpolatedText text={feature.title} />
        </Text>
        <Span position="relative" marginLeft={2}>
          <Button
            display="flex"
            lineHeight={0}
            padding={0}
            backgroundColor="transparent"
            border="none"
            className="pointer"
            onClick={handleToolTip}
          >
            <CFInfo size={16} color="gray1" />
          </Button>
          <div ref={ref}>
            <Media
              queries={{
                small: '(max-width: 991px)',
                large: '(min-width: 992px)',
              }}
              defaultMatches={{ large: true }}
            >
              {matches => (
                <Div
                  style={{
                    top: 0,
                    width: matches.large ? '320px' : '100%',
                    zIndex: matches.large ? 1 : 9999,
                    left: matches.large ? '' : 0,
                    right: 0,
                    transition: 'width 2s linear 1s',
                  }}
                  position={matches.large ? 'absolute' : 'fixed'}
                  zIndex={1}
                  heightPercentage={matches.large ? undefined : 100}
                  backgroundColor="blue4"
                  padding={3}
                  justifyContent="center"
                  alignItems="center"
                  display={showToolTip ? 'flex' : 'none'}
                >
                  <Div
                    heightPercentage={100}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    position="relative"
                  >
                    <Div display="flex">
                      <Text flex="auto" marginBottom={1} variant="body1-bold">
                        <InterpolatedText text={feature.title} />
                      </Text>
                      <Button
                        onClick={handleToolTip}
                        display="flex"
                        style={{ top: 0, right: 0 }}
                        lineHeight={0}
                        padding={0}
                        position={['absolute', 'absolute', 'absolute', 'relative']}
                        className="pointer"
                        backgroundColor="transparent"
                        border="none"
                      >
                        <CFXExit size={14} color="black" />
                      </Button>
                    </Div>
                    <Text variant="body2">
                      <InterpolatedText text={feature.description} />
                    </Text>
                  </Div>
                </Div>
              )}
            </Media>
          </div>
        </Span>
      </Div>
    </Div>
  );
};

interface BladeEnterpriseFormProps {
  blade: ContentfulBladeEnterpriseForm;
}

export function isEnterpriseFormBlade(blade: RelatedBlade): blade is ContentfulBladeEnterpriseForm {
  return blade.contentTypeId === 'bladeEnterpriseForm';
}

export const BladeEnterpriseForm: React.FC<BladeEnterpriseFormProps> = ({ blade }) => {
  const staticStrings = useStaticStrings();
  const [countryCode, setCountryCode] = React.useState('US');

  React.useEffect(() => {
    const redwood = (window as MaybeRedwood).redwood;
    setCountryCode(redwood?.country || countryCode);
  }, []);

  const salesPhoneNumber = i18nPhoneNumberMapping[countryCode] || i18nPhoneNumberMapping['i18n'];

  return (
    <Div
      backgroundColor={blade.backgroundColor === 'white' ? 'white' : 'blue5'}
      paddingTop={[4, 4, 4, 9]}
      paddingBottom={[7, 7, 7, 10]}
    >
      <Container>
        <Row display="flex" marginBottom={[6, 6, 6, 0]}>
          <Col marginBottom={[6, 6, 6, 0]} lg={5}>
            {blade.rightTitle && (
              <Text tag="H1" marginBottom={3} variant="headline1">
                {blade.rightTitle}
              </Text>
            )}
            {blade.rightSubtitle && (
              <Text tag="H5" marginBottom={2} variant="headline5">
                {blade.rightSubtitle}
              </Text>
            )}
            {blade.rightImage && (
              <Lazy>
                <Img marginBottom={3} src={blade.rightImage.file?.publicURL} />
              </Lazy>
            )}
            {blade.features?.length && (
              <Div>
                {blade.features.map((feature, index) => (
                  <FormFeatures
                    lastIndex={index === (blade.features?.length || 0) - 1}
                    key={feature.contentfulId}
                    feature={feature}
                  />
                ))}
              </Div>
            )}
            {blade.moreFeaturesLinkText && (
              <MoreFeaturesModal
                moreFeatures={blade.moreFeatures}
                moreFeaturesModalTitle={blade.moreFeaturesModalTitle}
                moreFeaturesModalLearnMoreUrl={blade.moreFeaturesModalLearnMoreUrl}
                moreFeaturesModalLearnMoreText={blade.moreFeaturesModalLearnMoreText}
                moreFeaturesLinkText={blade.moreFeaturesLinkText}
              />
            )}
            <Div marginTop={7}>
              <Text tag="H3" marginBottom={2} variant="headline3">
                {staticStrings.json_values.Have_Questions_}
              </Text>
              <P>
                <Text tag="Span" marginBottom={3} variant="body1">
                  {staticStrings.json_values.Call_sales_at}:{' '}
                </Text>
                <A href={`tel:${salesPhoneNumber.number}`}>
                  <Text tag="Span" color="blue1" marginBottom={3} variant="body1">
                    {salesPhoneNumber.displayedNumber}:
                  </Text>
                </A>
              </P>
            </Div>
          </Col>
          <Col lg={{ span: 4, offset: 2 }}>
            {blade.marketoEmbeddedForm && (
              <Div marginBottom={5}>
                <MarketoFormStyled
                  onSuccess={() => null}
                  title={blade.leftTitle}
                  subtitle={blade.leftSubtitle}
                  marketoData={blade.marketoEmbeddedForm}
                  SuccessElement={() =>
                    blade.marketoEmbeddedForm ? (
                      <OnSuccessMessage form={blade.marketoEmbeddedForm} ctaAction={() => null} />
                    ) : null
                  }
                />
              </Div>
            )}
            <Div>
              <Text tag="Span" variant="body2-bold">
                {staticStrings.json_values.Looking_for_support_}{' '}
              </Text>
              <LocaleLink variant="anchor" to="https://support.cloudflare.com/">
                <Text tag="Span" color="blue1" variant="body2">
                  {staticStrings.json_values.Click_here}
                </Text>
              </LocaleLink>
            </Div>
          </Col>
        </Row>
      </Container>
    </Div>
  );
};
