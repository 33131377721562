import { MRKAudienceSegmentFact } from '../../../contentful/content-types/personalization';

export const isTrue = <T extends Partial<{ [x: string]: string | boolean | number | null }>>(
  data: MRKAudienceSegmentFact[],
  comparator: T
): boolean => {
  return data?.every(
    ({ fact, value }: MRKAudienceSegmentFact) =>
      (value ?? false)?.toString() === (comparator[fact] ?? false)?.toString()
  );
};
