import React from 'react';
import { Div } from '../Elements';
import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { Document, BLOCKS } from '@contentful/rich-text-types';
import './richTextRenderer.style.scss';
import { ContentfulRichTextColumn } from '../../../contentful/content-types/blades/blade-richtext-columns';

interface RichTextRendererProps {
  richText: ContentfulRichTextColumn;
  options: Partial<Options>;
}

const isDocument = (richText: ContentfulRichTextColumn | Document): richText is Document => {
  return richText.content !== undefined && richText.nodeType === BLOCKS.DOCUMENT;
};

export const RichTextRenderer: React.FC<RichTextRendererProps> = ({ richText, options }) => {
  if (!isDocument(richText)) {
    throw new Error('Invalid Richtext document');
  }
  return <Div className="rich-text-renderer">{documentToReactComponents(richText, options)}</Div>;
};
