import * as React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { H3, H5, H2, Div, P, A, Span } from '../Elements';
import { MarkdownBlock } from '../MarkdownBlock';
import { ButtonCTA } from '../Button';
import {
  ContentfulAssetFile,
  ContentfulAssetFileURL,
} from '../ContentfulAssetFile/ContentfulAssetFile';
import { Divider } from '../Divider';
import { RelatedBlade } from '../../../contentful/blades';
import { ContentfulBladeImageText } from '../../../contentful/content-types/blades/blade-image-text/blade-image-text.interface';
import { Subtitle } from '../Subtitle';
import getPadding from '../BladePadding';
import { Lazy } from '../Lazy';
import { Stream } from '../Stream';
export function isContentfulBladeImageText(blade: RelatedBlade): blade is ContentfulBladeImageText {
  return blade.contentTypeId === 'bladeImageText';
}

interface ImageTextBladeProps {
  blade: ContentfulBladeImageText;
}

const getClassnameFromBackgroundColor = new Map([
  ['light-blue', 'bg-blue5'],
  ['blue', 'bg-blue5'],
  ['white', 'bg-white'],
]);

export const ImageTextBlade: React.FC<ImageTextBladeProps> = ({ blade }) => {
  if (blade.image || blade.streamVideoId || blade.imageAssetFile) {
    // The image and copy section can be swapped depending on the imagePosition option
    const imageOrderProp: 'first' | 'last' = blade.imagePosition ? 'first' : 'last';
    const copyOrderProp: 'first' | 'last' = blade.imagePosition ? 'last' : 'first';

    const imageSectionWidth = (function() {
      if (blade.imageSize == 'large') {
        return '6';
      } else if (blade.imageSize == 'extra-large') {
        return '8';
      } else {
        return '5';
      }
    })();

    const copyProps = (function() {
      if (blade.imageSize == 'extra-large') {
        return { order: copyOrderProp };
      } else {
        return { order: copyOrderProp, span: 5 };
      }
    })();
    return (
      <Div
        display="flex"
        className={`${getClassnameFromBackgroundColor.get(blade.backgroundColor as string) || ''}`}
      >
        <Container>
          {(blade.bladeBorder === 'border-top' || blade.bladeBorder === 'border') && (
            <Divider paddingVertical={0} />
          )}
          <Div {...getPadding(blade.sectionPadding, blade.contentTypeId)}>
            <Row
              className={`ph0-l ${
                blade.imageTextAlignment === 'top' ? 'items-start' : 'items-center'
              }`}
            >
              {/* Copy Section, automatically sizes around image section size. */}
              <Col lg={copyProps}>
                {blade.sectionTitle && <Subtitle marginBottom={1}>{blade.sectionTitle}</Subtitle>}
                {blade.titleSize && blade.titleSize == 'large' ? (
                  <H2 marginBottom={[4, 4, 4, 3]}>{blade.title}</H2>
                ) : (
                  <H3 marginBottom={[3, 3, 3, 2]}>{blade.title}</H3>
                )}

                {/* Image section on mobile, it appears under the title */}
                <ContentfulAssetFile
                  assetFile={blade.imageAssetFile}
                  fallBackUrl={blade.image?.file?.publicURL}
                  className={`mw-100 db dn-l center mb1 mb0-l`}
                />
                {blade.streamVideoId && (
                  <Div paddingBottom={3} display={['block', 'block', 'block', 'none']}>
                    <ContentfulAssetFileURL
                      assetFile={blade.streamPosterAssetFile}
                      fallBackUrl={blade.streamPoster?.file?.publicURL}
                    >
                      {({ url }) =>
                        (blade.streamVideoId && (
                          <Div>
                            <Lazy>
                              <Stream
                                src={blade.streamVideoId}
                                preload="auto"
                                loop={blade.streamOptions?.includes('loop') || false}
                                muted={blade.streamOptions?.includes('muted') || false}
                                controls={blade.streamOptions?.includes('controls') || false}
                                autoplay={blade.streamOptions?.includes('autoplay') || false}
                                poster={url || blade.streamPoster?.file?.publicURL || undefined}
                                posterTime={
                                  url && blade.streamThumbnails ? blade.streamThumbnails : '1s'
                                }
                              />
                            </Lazy>
                          </Div>
                        )) ||
                        null
                      }
                    </ContentfulAssetFileURL>
                  </Div>
                )}

                {blade.subheadline && (
                  <H5 marginTop={[2, 2, 2, 0]} marginBottom={[2, 2, 2, 0]}>
                    {blade.subheadline}
                  </H5>
                )}
                <Div>{blade.copy && <MarkdownBlock>{blade.copy}</MarkdownBlock>}</Div>
                {blade.button1Text && (
                  <Div display="flex" flexDirection="row" flexWrap="wrap" marginTop={[6, 6, 6, 7]}>
                    {blade.button1Text && (
                      <ButtonCTA
                        buttonColor={blade.button1Color}
                        href={blade.button1Url}
                        className={`mr4-l ${blade.button2Text ? 'mb2  mb2-l' : 'mb0'}`}
                      >
                        {blade.button1Text}
                      </ButtonCTA>
                    )}
                    {blade.button2Text && (
                      <ButtonCTA
                        buttonColor={blade.button2Color}
                        href={blade.button2Url}
                        className="mr4-l mb0-l"
                      >
                        {blade.button2Text}
                      </ButtonCTA>
                    )}
                  </Div>
                )}

                {blade.learnMoreUrl && (
                  <A
                    display="inline-block"
                    marginTop={blade.copy ? 0 : 4}
                    href={blade.learnMoreUrl}
                    className="learn-more"
                  >
                    {blade.learnMoreText}
                  </A>
                )}
                {blade.featureList && (
                  <Div marginTop={3}>
                    {blade.featureList.map((f, i) => (
                      <Div
                        className={`flex items-center ${
                          blade.featureList && i === blade.featureList.length - 1
                            ? 'mt3 mb0'
                            : 'mv3'
                        }`}
                      >
                        {f.icon && (
                          <Lazy>
                            <img src={f.icon.file?.publicURL} className="mr2" />
                          </Lazy>
                        )}
                        <Span lineHeight="copy" fontWeight={4}>
                          {f.title}
                        </Span>
                      </Div>
                    ))}
                  </Div>
                )}
              </Col>

              {/* Persistent 1 col gap between the 2 sections, except on extra wide image */}

              {blade.imageSize != 'extra-large' && <Col lg={1}></Col>}

              {/* Image section on desktop */}
              <Col
                xs={12}
                lg={{ span: imageSectionWidth, order: imageOrderProp }}
                className="dn db-l"
              >
                <ContentfulAssetFile
                  assetFile={blade.imageAssetFile}
                  fallBackUrl={blade.image?.file?.publicURL}
                  className={`mw-100 ${blade.imagePosition ? '' : 'center'} center db`}
                />
                {blade.streamVideoId && (
                  <Div paddingBottom={3} display={['none', 'none', 'none', 'block']}>
                    <ContentfulAssetFileURL
                      assetFile={blade.streamPosterAssetFile}
                      fallBackUrl={blade.streamPoster?.file?.publicURL}
                    >
                      {({ url }) =>
                        (blade.streamVideoId && (
                          <Div>
                            <Lazy>
                              <Stream
                                src={blade.streamVideoId}
                                preload="auto"
                                loop={blade.streamOptions?.includes('loop') || false}
                                muted={blade.streamOptions?.includes('muted') || false}
                                controls={blade.streamOptions?.includes('controls') || false}
                                autoplay={blade.streamOptions?.includes('autoplay') || false}
                                poster={url || blade.streamPoster?.file?.publicURL || undefined}
                                posterTime={
                                  url && blade.streamThumbnails ? blade.streamThumbnails : '1s'
                                }
                              />
                            </Lazy>
                          </Div>
                        )) ||
                        null
                      }
                    </ContentfulAssetFileURL>
                  </Div>
                )}
              </Col>
            </Row>
          </Div>
          {(blade.bladeBorder === 'border-bottom' || blade.bladeBorder === 'border') && (
            <Divider marginVertical={0} />
          )}
        </Container>
      </Div>
    );
  } else {
    // Blade without image
    return (
      <Div className={getClassnameFromBackgroundColor.get(blade.backgroundColor || '')}>
        <Container>
          {(blade.bladeBorder === 'border-top' || blade.bladeBorder === 'border') && (
            <Divider marginVertical={0} />
          )}
          <Div {...getPadding(blade.sectionPadding, blade.contentTypeId)}>
            <Row className={`center ph0-l`}>
              {/* Copy Ssection, automatically sizes around image section size. */}
              <Col xs={12} lg={6}>
                {blade.titleSize && blade.titleSize == 'large' ? (
                  <H2 marginBottom={[2, 2, 2, 1]}>{blade.title}</H2>
                ) : (
                  <H3 marginBottom={[2, 2, 2, 1]}>{blade.title}</H3>
                )}
              </Col>
              <Col lg={1}></Col>
              <Col xs={12} lg={5}>
                <Div paddingBottom={4}>
                  {blade.subheadline && (
                    <H5 marginTop={[1, 1, 1, 0]} marginBottom={[0, 0, 0, 1]}>
                      {blade.subheadline}
                    </H5>
                  )}
                  {blade.copy && <MarkdownBlock>{blade.copy}</MarkdownBlock>}
                </Div>
              </Col>
            </Row>
          </Div>
          {(blade.bladeBorder === 'border-bottom' || blade.bladeBorder === 'border') && (
            <Divider paddingVertical={0} />
          )}
        </Container>
      </Div>
    );
  }
};
