import * as React from 'react';
import { useStaticStrings } from '../../../common/hooks/useStaticStrings';
import { RelatedBlade } from '../../../contentful/blades';
import { ContentfulBladePricingPlans } from '../../../contentful/content-types/blades/blade-pricing-plans/blade-pricing-plans.interface';
import { ButtonCTA } from '../ButtonLink';
import { Divider } from '../Divider';
import { Div, H3, P, A, Button, Img, Container, Row, Col, Span } from '../Elements';
import { Icon } from '../Icon';
import { Lazy } from '../Lazy';
import { MarkdownBlock } from '../MarkdownBlock';
import useDimensions from 'react-use-dimensions';
import { BackgroundColor } from '../../../contentful/content-types/blades/common/interfaces/background-color.interface';
import getPadding from '../BladePadding';
import { Color } from '../Elements/types';

export function isContentfulBladePricingPlans(
  blade: RelatedBlade
): blade is ContentfulBladePricingPlans {
  return blade.contentTypeId === 'bladePricingPlans';
}

function isDimensionsObject(x: any): x is Dimensions {
  return !!x && typeof x.height === 'number';
}

interface Dimensions {
  width: number;
  height: number;
  top: number;
  left: number;
  x: number;
  y: number;
  right: number;
  bottom: number;
}

interface PricingPlansBladeProps {
  blade: ContentfulBladePricingPlans;
}

const getClassnameFromBackgroundColor: { [key in BackgroundColor]: Color } = {
  white: 'white',
  'light-blue': 'blue5',
};

export const PricingPlansBlade: React.FC<PricingPlansBladeProps> = props => {
  const { blade } = props;
  const { sectionPadding } = blade;

  const staticStrings = useStaticStrings();

  const [descriptionRef1, descriptionSize1] = useDimensions();
  const [descriptionRef2, descriptionSize2] = useDimensions();
  const [descriptionRef3, descriptionSize3] = useDimensions();
  const [descriptionRef4, descriptionSize4] = useDimensions();

  const descriptionSizeRefs: any = {
    1: descriptionRef1,
    2: descriptionRef2,
    3: descriptionRef3,
    4: descriptionRef4,
  };

  const [descriptionHeights, setDescriptionHeights] = React.useState<number[]>([]);
  const [descriptionStyle, setDescriptionStyle] = React.useState({});

  const [planTypeRef1, planTypeSize1] = useDimensions();
  const [planTypeRef2, planTypeSize2] = useDimensions();
  const [planTypeRef3, planTypeSize3] = useDimensions();
  const [planTypeRef4, planTypeSize4] = useDimensions();

  const planTypeSizeRefs: any = {
    1: planTypeRef1,
    2: planTypeRef2,
    3: planTypeRef3,
    4: planTypeRef4,
  };

  const [planTypeHeights, setPlanTypeHeights] = React.useState<number[]>([]);
  const [planTypeStyle, setPlanTypeStyle] = React.useState({});

  const [priceContainerRef1, priceContainerSize1] = useDimensions();
  const [priceContainerRef2, priceContainerSize2] = useDimensions();
  const [priceContainerRef3, priceContainerSize3] = useDimensions();
  const [priceContainerRef4, priceContainerSize4] = useDimensions();

  const priceContainerSizeRefs: any = {
    1: priceContainerRef1,
    2: priceContainerRef2,
    3: priceContainerRef3,
    4: priceContainerRef4,
  };

  const [priceContainerHeights, setPriceContainerHeights] = React.useState<number[]>([]);
  const [priceContainerStyle, setPriceContainerStyle] = React.useState({});

  const [priceRef1, priceSize1] = useDimensions();
  const [priceRef2, priceSize2] = useDimensions();
  const [priceRef3, priceSize3] = useDimensions();
  const [priceRef4, priceSize4] = useDimensions();

  const priceSizeRefs: any = {
    1: priceRef1,
    2: priceRef2,
    3: priceRef3,
    4: priceRef4,
  };

  const [priceHeights, setPriceHeights] = React.useState<number[]>([]);
  const [priceStyle, setPriceStyle] = React.useState({});

  const [billingInfoRef1, billingInfoSize1] = useDimensions();
  const [billingInfoRef2, billingInfoSize2] = useDimensions();
  const [billingInfoRef3, billingInfoSize3] = useDimensions();
  const [billingInfoRef4, billingInfoSize4] = useDimensions();

  const billingInfoSizeRefs: any = {
    1: billingInfoRef1,
    2: billingInfoRef2,
    3: billingInfoRef3,
    4: billingInfoRef4,
  };

  const [billingInfoHeights, setBillingInfoHeights] = React.useState<number[]>([]);
  const [billingInfoStyle, setBillingInfoStyle] = React.useState({});

  const [featureList1Ref1, featureList1Size1] = useDimensions();
  const [featureList1Ref2, featureList1Size2] = useDimensions();
  const [featureList1Ref3, featureList1Size3] = useDimensions();
  const [featureList1Ref4, featureList1Size4] = useDimensions();

  const featureList1SizeRefs: any = {
    1: featureList1Ref1,
    2: featureList1Ref2,
    3: featureList1Ref3,
    4: featureList1Ref4,
  };

  const [featureList1Heights, setFeatureList1Heights] = React.useState<number[]>([]);
  const [featureList1Style, setFeatureList1Style] = React.useState({});

  const [featureList2Ref1, featureList2Size1] = useDimensions();
  const [featureList2Ref2, featureList2Size2] = useDimensions();
  const [featureList2Ref3, featureList2Size3] = useDimensions();
  const [featureList2Ref4, featureList2Size4] = useDimensions();

  const featureList2SizeRefs: any = {
    1: featureList2Ref1,
    2: featureList2Ref2,
    3: featureList2Ref3,
    4: featureList2Ref4,
  };

  const [featureList2Heights, setFeatureList2Heights] = React.useState<number[]>([]);
  const [featureList2Style, setFeatureList2Style] = React.useState({});

  const [featureList3Ref1, featureList3Size1] = useDimensions();
  const [featureList3Ref2, featureList3Size2] = useDimensions();
  const [featureList3Ref3, featureList3Size3] = useDimensions();
  const [featureList3Ref4, featureList3Size4] = useDimensions();

  const featureList3SizeRefs: any = {
    1: featureList3Ref1,
    2: featureList3Ref2,
    3: featureList3Ref3,
    4: featureList3Ref4,
  };

  const [featureList3Heights, setFeatureList3Heights] = React.useState<number[]>([]);
  const [featureList3Style, setFeatureList3Style] = React.useState({});

  const [supportResponseRef1, supportResponseSize1] = useDimensions();
  const [supportResponseRef2, supportResponseSize2] = useDimensions();
  const [supportResponseRef3, supportResponseSize3] = useDimensions();
  const [supportResponseRef4, supportResponseSize4] = useDimensions();

  const supportResponseSizeRefs: any = {
    1: supportResponseRef1,
    2: supportResponseRef2,
    3: supportResponseRef3,
    4: supportResponseRef4,
  };

  const [supportResponseHeights, setSupportResponseHeights] = React.useState<number[]>([]);
  const [supportResponseStyle, setSupportResponseStyle] = React.useState({});

  function setStyles() {
    setDescriptionStyle(
      window.matchMedia('(min-width: 993px)').matches
        ? {
            height:
              descriptionHeights.length > 0
                ? Math.max(...descriptionHeights).toString() + 'px'
                : 'auto',
          }
        : {}
    );

    setPlanTypeStyle(
      window.matchMedia('(min-width: 993px)').matches
        ? {
            height:
              planTypeHeights.length > 0 ? Math.max(...planTypeHeights).toString() + 'px' : 'auto',
          }
        : {}
    );

    setPriceContainerStyle(
      window && window.matchMedia('(min-width: 993px)').matches
        ? {
            height:
              priceContainerHeights.length > 0
                ? Math.max(...priceContainerHeights).toString() + 'px'
                : 'auto',
          }
        : {}
    );

    setPriceStyle(
      window && window.matchMedia('(min-width: 993px)').matches
        ? {
            height: priceHeights.length > 0 ? Math.max(...priceHeights).toString() + 'px' : 'auto',
          }
        : {}
    );

    setBillingInfoStyle(
      window && window.matchMedia('(min-width: 993px)').matches
        ? {
            height:
              billingInfoHeights.length > 0
                ? Math.max(...billingInfoHeights).toString() + 'px'
                : 'auto',
          }
        : {}
    );

    setFeatureList1Style(
      window && window.matchMedia('(min-width: 993px)').matches
        ? {
            height:
              featureList1Heights.length > 0
                ? Math.max(...featureList1Heights).toString() + 'px'
                : 'auto',
          }
        : {}
    );

    setFeatureList2Style(
      window && window.matchMedia('(min-width: 993px)').matches
        ? {
            height:
              featureList2Heights.length > 0
                ? Math.max(...featureList2Heights).toString() + 'px'
                : 'auto',
          }
        : {}
    );

    setFeatureList3Style(
      window && window.matchMedia('(min-width: 993px)').matches
        ? {
            height:
              featureList3Heights.length > 0
                ? Math.max(...featureList3Heights).toString() + 'px'
                : 'auto',
          }
        : {}
    );

    setSupportResponseStyle(
      window && window.matchMedia('(min-width: 993px)').matches
        ? {
            height:
              supportResponseHeights.length > 0
                ? Math.max(...supportResponseHeights).toString() + 'px'
                : 'auto',
          }
        : {}
    );
  }

  function equalHeights() {
    if (
      isDimensionsObject(descriptionSize1) &&
      isDimensionsObject(descriptionSize2) &&
      isDimensionsObject(descriptionSize3)
    ) {
      const descriptionHeight1: number = descriptionSize1.height;
      const descriptionHeight2: number = descriptionSize2.height;
      const descriptionHeight3: number = descriptionSize3.height;
      const descriptionHeight4: any = isDimensionsObject(descriptionSize4)
        ? descriptionSize4.height
        : undefined;

      setDescriptionHeights(
        [
          descriptionHeight1,
          descriptionHeight2,
          descriptionHeight3,
          descriptionHeight4,
        ].filter(ele => Number.isFinite(ele))
      );
    }
    if (
      isDimensionsObject(planTypeSize1) &&
      isDimensionsObject(planTypeSize2) &&
      isDimensionsObject(planTypeSize3)
    ) {
      const planTypeHeight1: number = planTypeSize1.height;
      const planTypeHeight2: number = planTypeSize2.height;
      const planTypeHeight3: number = planTypeSize3.height;
      const planTypeHeight4: any = isDimensionsObject(planTypeSize4)
        ? planTypeSize4.height
        : undefined;

      setPlanTypeHeights(
        [planTypeHeight1, planTypeHeight2, planTypeHeight3, planTypeHeight4].filter(ele =>
          Number.isFinite(ele)
        )
      );
    }

    if (
      isDimensionsObject(priceSize1) &&
      isDimensionsObject(priceSize2) &&
      isDimensionsObject(priceSize3)
    ) {
      const priceHeight1: number = priceSize1.height;
      const priceHeight2: number = priceSize2.height;
      const priceHeight3: number = priceSize3.height;
      const priceHeight4: any = isDimensionsObject(priceSize4) ? priceSize4.height : undefined;

      setPriceHeights(
        [priceHeight1, priceHeight2, priceHeight3, priceHeight4].filter(ele => Number.isFinite(ele))
      );
    }
    if (
      isDimensionsObject(priceContainerSize1) &&
      isDimensionsObject(priceContainerSize2) &&
      isDimensionsObject(priceContainerSize3)
    ) {
      const priceContainerHeight1: number = priceContainerSize1.height;
      const priceContainerHeight2: number = priceContainerSize2.height;
      const priceContainerHeight3: number = priceContainerSize3.height;
      const priceContainerHeight4: any = isDimensionsObject(priceContainerSize4)
        ? priceContainerSize4.height
        : undefined;

      setPriceContainerHeights(
        [
          priceContainerHeight1,
          priceContainerHeight2,
          priceContainerHeight3,
          priceContainerHeight4,
        ].filter(ele => Number.isFinite(ele))
      );
    }
    if (
      isDimensionsObject(billingInfoSize1) &&
      isDimensionsObject(billingInfoSize2) &&
      isDimensionsObject(billingInfoSize3)
    ) {
      const billingInfoHeight1: number = billingInfoSize1.height;
      const billingInfoHeight2: number = billingInfoSize2.height;

      const billingInfoHeight3: number = billingInfoSize3.height;

      const billingInfoHeight4: any = isDimensionsObject(billingInfoSize4)
        ? billingInfoSize4.height
        : undefined;

      setBillingInfoHeights(
        [
          billingInfoHeight1,
          billingInfoHeight2,
          billingInfoHeight3,
          billingInfoHeight4,
        ].filter(ele => Number.isFinite(ele))
      );
    }

    if (
      isDimensionsObject(featureList1Size1) &&
      isDimensionsObject(featureList1Size2) &&
      isDimensionsObject(featureList1Size3)
    ) {
      const featureList1Height1: number = featureList1Size1.height;

      const featureList1Height2: number = featureList1Size2.height;

      const featureList1Height3: number = featureList1Size3.height;

      const featureList1Height4: any = isDimensionsObject(featureList1Size4)
        ? featureList1Size4.height
        : undefined;

      setFeatureList1Heights(
        [
          featureList1Height1,
          featureList1Height2,
          featureList1Height3,
          featureList1Height4,
        ].filter(ele => Number.isFinite(ele))
      );
    }

    if (
      isDimensionsObject(featureList2Size1) &&
      isDimensionsObject(featureList2Size2) &&
      isDimensionsObject(featureList2Size3)
    ) {
      const featureList2Height1: any = featureList2Size1.height;

      const featureList2Height2: any = featureList2Size2.height;

      const featureList2Height3: any = featureList2Size3.height;

      const featureList2Height4: any = isDimensionsObject(featureList2Size4)
        ? featureList2Size4.height
        : undefined;

      setFeatureList2Heights(
        [
          featureList2Height1,
          featureList2Height2,
          featureList2Height3,
          featureList2Height4,
        ].filter(ele => Number.isFinite(ele))
      );
    }

    if (
      isDimensionsObject(featureList3Size1) &&
      isDimensionsObject(featureList3Size2) &&
      isDimensionsObject(featureList3Size3)
    ) {
      const featureList3Height1: number = featureList3Size1.height;
      const featureList3Height2: number = featureList3Size2.height;
      const featureList3Height3: number = featureList3Size3.height;
      const featureList3Height4: any = isDimensionsObject(featureList3Size4)
        ? featureList3Size4.height
        : undefined;

      setFeatureList3Heights(
        [
          featureList3Height1,
          featureList3Height2,
          featureList3Height3,
          featureList3Height4,
        ].filter(ele => Number.isFinite(ele))
      );
    }

    if (
      isDimensionsObject(supportResponseSize1) &&
      isDimensionsObject(supportResponseSize2) &&
      isDimensionsObject(supportResponseSize3)
    ) {
      const supportResponseHeight1: number = supportResponseSize1.height;
      const supportResponseHeight2: number = supportResponseSize2.height;
      const supportResponseHeight3: number = supportResponseSize3.height;
      const supportResponseHeight4: any = isDimensionsObject(supportResponseSize4)
        ? supportResponseSize4.height
        : undefined;

      setSupportResponseHeights(
        [
          supportResponseHeight1,
          supportResponseHeight2,
          supportResponseHeight3,
          supportResponseHeight4,
        ].filter(ele => Number.isFinite(ele))
      );
    }
  }

  React.useEffect(() => {
    setStyles();

    setTimeout(function() {
      equalHeights();
    }, 1);
  }, [
    descriptionSize1,
    descriptionSize2,
    descriptionSize3,
    descriptionSize4,
    planTypeSize1,
    planTypeSize2,
    planTypeSize3,
    planTypeSize4,
    priceSize1,
    priceSize2,
    priceSize3,
    priceSize4,
    priceContainerSize1,
    priceContainerSize2,
    priceContainerSize3,
    priceContainerSize4,
    billingInfoSize1,
    billingInfoSize2,
    billingInfoSize3,
    billingInfoSize4,
    featureList1Size1,
    featureList1Size2,
    featureList1Size3,
    featureList1Size4,
    featureList2Size1,
    featureList2Size2,
    featureList2Size3,
    featureList2Size4,
    featureList3Size1,
    featureList3Size2,
    featureList3Size3,
    featureList3Size4,
    supportResponseSize1,
    supportResponseSize2,
    supportResponseSize3,
    supportResponseSize4,
  ]);

  return (
    <>
      <Div
        backgroundColor={getClassnameFromBackgroundColor[props.blade.backgroundColor] || 'white'}
        {...getPadding(sectionPadding, blade.contentTypeId)}
      >
        <Container>
          {(blade.bladeBorder === 'border-top' || blade.bladeBorder === 'border') && (
            <Divider paddingVertical={0} />
          )}
          <Div>
            <Row marginBottom={0}>
              <Col lg={7}>
                <H3 marginBottom={[1, 10]} fontSize={[6, 8]} lineHeight={5}>
                  {blade.title}
                </H3>
                {blade.description && <P>{blade.description}</P>}
              </Col>
            </Row>
            <Row>
              {blade.plansList?.map((plan, i, plansList) => (
                <Col lg={plansList.length === 4 ? '3' : '4'} key={plan.contentTypeId + i}>
                  <Divider paddingVertical={3}></Divider>

                  <Div>
                    <div ref={planTypeSizeRefs[i + 1]} style={planTypeStyle}>
                      {plan.type && <H3 color={'orange0'}>{plan.type}</H3>}
                    </div>
                    {plan.description && (
                      <div ref={descriptionSizeRefs[i + 1]} style={descriptionStyle}>
                        <P className="body-2" marginBottom={0} marginTop={2}>
                          {plan.description}
                        </P>
                      </div>
                    )}
                  </Div>
                  <Divider paddingVertical={3}></Divider>
                  <div ref={priceContainerSizeRefs[i + 1]} style={priceContainerStyle}>
                    <div ref={priceSizeRefs[i + 1]} style={priceStyle}>
                      <H3>
                        {plan.priceCurrencySymbol}
                        {plan.price}
                      </H3>
                    </div>
                    <div ref={billingInfoSizeRefs[i + 1]} style={billingInfoStyle}>
                      <P color={'gray0'} className="body-2" marginTop={2}>
                        {plan.billingSubtext ? plan.billingSubtext : ''}
                      </P>
                    </div>
                    <Div>
                      <ButtonCTA
                        fontWeight="bold"
                        border="all"
                        color="white"
                        buttonColor="orange"
                        width="100%"
                        marginTop={2}
                        marginBottom={!plan.featureList1 && !plan.additionalTermsText ? 4 : 0}
                        className="pointer"
                        href={plan.ctaButtonUrl}
                        data-tracking-category={`${plan.type}_plan`}
                        data-tracking-action="click"
                        data-tracking-label={`${plan.ctaButtonText}-${plan.ctaButtonUrl}`}
                      >
                        {plan.ctaButtonText}
                      </ButtonCTA>
                    </Div>
                    {plan.additionalTermsText && (
                      <P color={'gray0'} marginBottom={0} margin-top={1} className="body-3">
                        {plan.additionalTermsText ? plan.additionalTermsText : ''}
                      </P>
                    )}
                  </div>

                  {plan.featureList1 && (
                    <>
                      <Divider paddingVertical={3}></Divider>
                      <div ref={featureList1SizeRefs[i + 1]} style={featureList1Style}>
                        <Div width={plansList.length === 4 ? '100%' : '90%'}>
                          <Div>
                            <P
                              color="orange0"
                              fontSize={2}
                              fontWeight="bold"
                              marginTop={0}
                              marginBottom={1}
                              lineHeight={6}
                              className="ttu"
                            >
                              {plan.featureList1.title}
                            </P>
                            <Div>
                              {plan.featureList1.features.map(feature => (
                                <Div marginTop={2} className="flex items-start">
                                  <Lazy>
                                    <img
                                      className="mr2"
                                      style={{ marginTop: '4px' }}
                                      alt="cloudflare-checkmark"
                                      src="/img/icons/orange/checkmark.svg"
                                    />
                                  </Lazy>
                                  <MarkdownBlock pClassName={'di f2 fw6 lh-6'}>
                                    {feature.title}
                                  </MarkdownBlock>
                                </Div>
                              ))}
                            </Div>
                          </Div>
                        </Div>
                      </div>
                    </>
                  )}

                  {plan.featureList2 && (
                    <>
                      <Divider paddingVertical={3}></Divider>
                      <div ref={featureList2SizeRefs[i + 1]} style={featureList2Style}>
                        <Div width={plansList.length === 4 ? '100%' : '90%'}>
                          <Div>
                            <P
                              color="orange0"
                              fontSize={2}
                              fontWeight="bold"
                              marginTop={0}
                              marginBottom={1}
                              lineHeight={6}
                              className="ttu"
                            >
                              {plan.featureList2?.title}
                            </P>
                            <Div>
                              {plan.featureList2.features.map(feature => (
                                <Div marginTop={2} className="flex items-start">
                                  <Lazy>
                                    <img
                                      className="mr2"
                                      style={{ marginTop: '4px' }}
                                      alt="cloudflare-checkmark"
                                      src="/img/icons/orange/checkmark.svg"
                                    />
                                  </Lazy>
                                  <MarkdownBlock pClassName={'di f2 fw6 lh-6'}>
                                    {feature.title}
                                  </MarkdownBlock>
                                </Div>
                              ))}
                            </Div>
                          </Div>
                        </Div>
                      </div>
                    </>
                  )}

                  {plan.featureList3 && (
                    <>
                      <Divider paddingVertical={3}></Divider>
                      <div ref={featureList3SizeRefs[i + 1]} style={featureList3Style}>
                        <Div width={plansList.length === 4 ? '100%' : '90%'}>
                          <Div>
                            <P
                              color="orange0"
                              fontSize={2}
                              fontWeight="bold"
                              marginTop={0}
                              marginBottom={1}
                              lineHeight={6}
                              className="ttu"
                            >
                              {plan.featureList3?.title}
                            </P>
                            <Div>
                              {plan.featureList3.features.map(feature => (
                                <Div marginTop={2} className="flex items-start">
                                  <Lazy>
                                    <img
                                      className="mr2"
                                      style={{ marginTop: '4px' }}
                                      alt="cloudflare-checkmark"
                                      src="/img/icons/orange/checkmark.svg"
                                    />
                                  </Lazy>
                                  <MarkdownBlock pClassName={'di f2 fw6 lh-6'}>
                                    {feature.title}
                                  </MarkdownBlock>
                                </Div>
                              ))}
                            </Div>
                          </Div>
                        </Div>
                      </div>
                    </>
                  )}

                  {plan.supportResponse && (
                    <>
                      <Divider paddingTop={3} paddingBottom={2} marginTop={3}></Divider>
                      <div ref={supportResponseSizeRefs[i + 1]} style={supportResponseStyle}>
                        <P
                          fontSize={2}
                          marginTop={0}
                          lineHeight={6}
                          fontWeight={4}
                          color={'black'}
                          width={plansList.length === 4 ? '100%' : '90%'}
                          marginBottom={0}
                        >
                          <Span fontWeight={6}>
                            {staticStrings.json_values['Support_Response']}:
                          </Span>{' '}
                          {plan.supportResponse}
                        </P>
                      </div>
                    </>
                  )}
                  {plan.bottomCtaLinkUrl && plan.bottomCtaLinkText && (
                    <>
                      <Divider></Divider>
                      <P margin={0} paddingBottom={i === plansList.length - 1 ? [0, 0] : [4, 0]}>
                        <A
                          href={plan.bottomCtaLinkUrl}
                          fontWeight="bold"
                          color="blue1"
                          className="learn-more"
                        >
                          {plan.bottomCtaLinkText}
                        </A>
                      </P>
                    </>
                  )}
                </Col>
              ))}
            </Row>
          </Div>
        </Container>
      </Div>
      <Container>
        {(blade.bladeBorder === 'border-bottom' || blade.bladeBorder === 'border') && (
          <Divider paddingVertical={0} />
        )}
      </Container>
    </>
  );
};
